import { useMemo } from "react";
import { Provider } from "urql";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import initUrql from "sholdi-shared/lib/urql/init-urql";
import { URQL_STATE_PROP_NAME } from "sholdi-shared/lib/urql/utils";
import AUTH_SECTIONS from "sholdi-shared/constants/authSections";

const UrqlProvider = ({ children, pageProps }) => {
  const router = useRouter();
  const { asPath } = router;
  const authSection = useMemo(
    () =>
      Object.keys(AUTH_SECTIONS).reduce((acc, key) => {
        if (asPath.includes(AUTH_SECTIONS[key].path)) {
          acc = key;
        }
        return acc;
      }, "public"),
    [asPath],
  );

  const { client, cache } = useMemo(() => initUrql(authSection), [authSection]);

  if (pageProps[URQL_STATE_PROP_NAME]) {
    cache.restoreData(pageProps[URQL_STATE_PROP_NAME]);
  }

  return <Provider value={client}>{children}</Provider>;
};

UrqlProvider.propTypes = {
  children: PropTypes.node.isRequired,
  pageProps: PropTypes.shape({
    urqlState: PropTypes.shape({}),
  }),
};

export default UrqlProvider;
