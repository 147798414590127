import { useCallback, useMemo, useState, createContext } from "react";
import PropTypes from "prop-types";

export const SettingsContext = createContext();

const CURRENCY_LIST = [
  { code: "BAM", symbol: "KM" },
  { code: "EUR", symbol: "€" },
];

const DEFAULT_SETTINGS = { currency: { code: "BAM", symbol: "KM" } };

export const SettingsProvider = ({
  children,
  settings: initialSettings,
  className,
}) => {
  const settings = useMemo(
    () => ({
      ...DEFAULT_SETTINGS,
      ...initialSettings,
    }),
    [initialSettings],
  );

  const [settingsState, setSettings] = useState(settings);

  const setCurrency = useCallback((currencyCode) => {
    const currency = CURRENCY_LIST.find(
      (currencyItem) => currencyItem.code === currencyCode,
    );
    setSettings((previousSettings) => ({ ...previousSettings, currency }));
  }, []);

  const { currency } = settingsState;

  const value = useMemo(
    () => ({ ...initialSettings, currency, setCurrency }),
    [currency, setCurrency, initialSettings],
  );

  return (
    <div className={className}>
      <SettingsContext.Provider value={value}>
        {children}
      </SettingsContext.Provider>
    </div>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.shape({}),
  className: PropTypes.string,
};
