import authSections from "../../constants/authSections";

import getAuthSection from "./getAuthSection";

export const URQL_STATE_PROP_NAME = "__URQL_STATE__";

const getRole = (session, authSection) => {
  let role = "PUBLIC";
  const selectedSection = authSections[authSection];
  const { requiredRoles } = selectedSection;

  if (session) {
    const userAllowedRoles = session.user.registrations.reduce((acc, reg) => {
      acc = [...acc, ...reg.roles];
      return acc;
    }, []);
    for (let i = 0; i < requiredRoles.length; i++) {
      const tmpRole = requiredRoles[i];
      if (userAllowedRoles.includes(tmpRole)) {
        role = tmpRole;
        break;
      }
    }
  }
  return role;
};

// eslint-disable-next-line no-unused-vars
export const getHeaders = (session, authSection, adminSecret) => {
  if (adminSecret) {
    return {
      "x-hasura-role": "admin",
      "x-hasura-admin-secret": adminSecret,
    };
  }

  // TODO: PASS HEADERS OBJECT AS THIRD PARAM IF NEEDED
  if (!session || !session.token) {
    return {
      "x-hasura-role": "PUBLIC",
    };
  }
  let finalAuthSection = authSection;
  const isServer = typeof window === "undefined";
  if (!isServer) {
    const pathname = window?.location?.pathname;
    finalAuthSection = getAuthSection(pathname);
  }
  const { token } = session;
  const role = getRole(session, finalAuthSection);
  return {
    Authorization: `Bearer ${token}`,
    "x-hasura-role": role,
  };
};

export function addUrqlState(cache, pageProps) {
  if (pageProps) {
    // eslint-disable-next-line no-param-reassign
    pageProps[URQL_STATE_PROP_NAME] = cache.extractData();
  }

  return pageProps;
}
